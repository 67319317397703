import { downLoadCSV, getValue } from "./csvExport.js";
import store from "@/store/vuex.js";
export function applicationReport(reports, name) {
  let header =
    "利用目的,ステータス,車両番号,レンタカー番号,日次承認,月次承認,所属部署,記録作成日,運転者,利用期間～,～利用期間,行き先・貸出し先,";
  //社有車利用
  header +=
    "前回距離,開始時間,開始時間(手動修正),現在距離,ETC利用,燃料(L),オイル(L),走行距離,異常個所および交換,終了時間,終了時間(手動修正),修正理由,オイル点検,";
  //酒気帯び運転確認:業務前
  header += "確認方法,確認者氏名,酒気帯び,確認時間,確認時間(手動),";
    //酒気帯び運転確認:業務後
  header += "確認方法,確認者氏名,酒気帯び,確認時間,確認時間(手動),";

  let csv = "";
  reports.map((report) => {
    let line = "";
    line += getValue(report.Recodes.FID8014) + ","; //利用目的
    line += getValue(report.Recodes.FID8021) + ","; //ステータス
    line += getValue(report.Recodes.FID8023) + ","; //車両番号
    line += getValue(report.Recodes.FID9584) + ","; //レンタカー番号
    line += getValue(report.Recodes.FID8616) + ","; //日次承認
    line += getValue(report.Recodes.FID8583) + ","; //月次承認
    if (line == "") {
      line += store.state.organization[getValue(report.Recodes.FID9572)] + ","; //所属部署
    } else {
      line += getValue(report.Recodes.FID9572) + ","; //所属部署
    }
    line += getValue(report.Recodes.FID8015) + ","; //記録作成日
    line += getValue(report.Recodes.FID8016) + ","; //運転者
    line += getValue(report.Recodes.FID8017) + ","; //利用期間～
    line += getValue(report.Recodes.FID8018) + ","; //～利用期間
    line += getValue(report.Recodes.FID8019) + ","; //行き先・貸出し先
    
    //社有車利用
    if (
      report.Recodes.FID8026.subReports != null &&
      report.Recodes.FID8026.subReports.length > 0
    ) {
      let subReport = report.Recodes.FID8026.subReports[0];
      line += getValue(subReport.Recodes.FID8255) + ","; //前回距離
      line += getValue(subReport.Recodes.FID8032) + ","; //開始時間
      line += getValue(subReport.Recodes.FID9998) + ","; //開始時間(手動修正)
      line += getValue(subReport.Recodes.FID8256) + ","; //現在距離
      line += getValue(subReport.Recodes.FID8034) + ","; //ETC利用
      line += getValue(subReport.Recodes.FID8035) + ","; //燃料(L)
      line += getValue(subReport.Recodes.FID8036) + ","; //オイル(L)
      line += getValue(subReport.Recodes.FID8270) + ","; //走行距離
      line += getValue(subReport.Recodes.FID8037) + ","; //異常個所および交換
      line += getValue(subReport.Recodes.FID8033) + ","; //終了時間
      line += getValue(subReport.Recodes.FID9999) + ","; //開始時間(手動修正)
      line += getValue(subReport.Recodes.FID10000) + ","; //修正理由
      line += getValue(subReport.Recodes.FID8582) + ","; //オイル点検
    } else {
      line = addCommas(line, 13);
    }

    //酒気帯び運転確認:業務前
    if (
      report.Recodes.FID8025.subReports != null &&
      report.Recodes.FID8025.subReports.length > 0
    ) {
      let subReport = report.Recodes.FID8025.subReports[0];
      line += getValue(subReport.Recodes.FID8029) + ","; //確認方法
      line += getValue(subReport.Recodes.FID8047) + ","; //確認者氏名
      //酒気帯びチェック
      if (subReport.Recodes.FID8253.value != "") {
        line += "無,"; //酒気帯び無
      } else if (subReport.Recodes.FID8039.value != "") {
        line += "有,"; //酒気帯び有
      } else {
        line += ","; //酒気帯び未入力
      }
      line += getValue(subReport.Recodes.FID8040) + ","; //確認時間
      line += getValue(subReport.Recodes.FID8385) + ","; //確認時間(手動)
      //
    } else {
      line = addCommas(line, 5);
    }
    
    //酒気帯び運転確認:業務後
    if (
      report.Recodes.FID8027.subReports != null &&
      report.Recodes.FID8027.subReports.length > 0
    ) {
      let subReport = report.Recodes.FID8027.subReports[0];
      //酒気帯びチェック
      line += getValue(subReport.Recodes.FID8042) + ","; //確認方法
      line += getValue(subReport.Recodes.FID8049) + ","; //確認者氏名
      if (subReport.Recodes.FID8254.value != "") {
        line += "無,"; //酒気帯び無
      } else if (subReport.Recodes.FID8044.value != "") {
        line += "有,"; //酒気帯び有
      } else {
        line += ","; //酒気帯び未入力
      }
      line += getValue(subReport.Recodes.FID8045) + ","; //確認時間
      line += getValue(subReport.Recodes.FID8389) + ","; //確認時間(手動)
    } else {
      line = addCommas(line, 5);
    }
    line += "\n";
    csv += line;
  });
  let file = "\ufeff" + header + "\n" + csv;
  //
  downLoadCSV(file, name);
}

// headerのカンマの数に応じてlineにカンマを追加する関数
function addCommas(line, count) {
  for (let i = 0; i < count; i++) {
    line += ",";
  }
  return line;
}
